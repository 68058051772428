import { DefaultTheme } from 'styled-components'

const generateTypeScale = (
  initialValue: number,
  numValues: number,
  scale: number
) => {
  return Array(numValues)
    .fill(null)
    .reduce((acc, val, index) => {
      if (index === 0) {
        acc.push(initialValue)
      } else {
        const newScale = acc[index - 1] * scale
        const rounded = Math.round(newScale * 1000) / 1000
        acc.push(rounded)
      }
      return acc
    }, [])
    .map((v: number) => `${v}rem`)
}

export const sizes: string[] & Record<string, any> = []

const space: string[] & Record<string, any> = [
  '0.0rem', // 0 - 0px
  '0.5rem', // 1 - 5px
  '1.0rem', // 2 - 10px
  '2.0rem', // 3 - 20px
  '4.0rem', // 4 - 40px
  '8.0rem', // 5 - 80px
  '16.0rem', // 6 - 160px
  '22.0rem', // 7 - 240px
  '32.0rem', // 8 - 320px
]

const breakpoints = [
  '30em', // 1 - 480px
  '45em', // 2 - 720px
  '60em', // 3 - 960px
  '75em', // 4 - 1200px
  // '90em', // 5 - 1440px
  '100em', // 5 - 1440px
  // '100em', // 5 - 1472px
]

const theme: DefaultTheme = {
  // Remember that em units in media queries are always relative to 16px / the user setting
  // and NOT the html font size!
  // This means generally speaking:
  breakpoints,
  borders: {},
  colors: {
    // grayscale
    black: '#000',
    white: '#ffffff',
    background: '#8f282e',
    text: '#ac5e30',
  },
  fonts: {
    sans: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  // Minor third
  fontSizes: generateTypeScale(0.715, 10, 1.2),
  fontWeights: {
    thin: 200,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    black: 800,
  },
  letterSpacings: {
    default: '0.012em',
  },
  lineHeights: {
    body: '1.1em',
    heading: '1.05em',
    single: '1em',
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
  sizes,
  space,
  zIndices: {
    debug: 9000,
    cart: 10,
    header: 9,
    content: 2,
    centeredLogo: 1,
  },
}

export default theme
