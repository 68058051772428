import React from 'react'

const Logo = () => {
  return (
    <svg width="100%" viewBox="0 0 500 105">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M189.495,89.578 L189.495,74.607 C189.495,67.934 188.608,58.962 181.731,53.885 C186.858,49.905 189.361,43.449 189.361,34.273 L189.361,26.651 C189.361,10.021 181.429,1.941 165.108,1.941 L140.448,1.941 L140.448,13.6 C136.672,5.991 129.387,1.941 119.043,1.941 L76.662,1.941 L76.662,79.185 C76.662,85.675 73.948,86.216 71.41,86.216 C68.864,86.216 66.15,85.675 66.15,79.185 L66.15,1.941 L46.616,1.941 L46.616,15.076 C43.198,5.893 35.555,0.83 24.522,0.83 C9.193,0.83 0.403,10.597 0.403,27.621 C0.403,39.751 5.009,48.456 17.42,59.75 C27.412,68.785 29.24,73.335 29.24,79.045 C29.24,85.535 26.526,86.076 23.98,86.076 C21.442,86.076 18.735,85.535 18.735,79.045 L18.735,69.727 L0.002,69.727 L0.002,78.074 C0.002,95.098 8.89,104.858 24.388,104.858 C36.715,104.858 44.865,98.684 47.678,87.581 C50.462,98.768 58.619,104.999 71.002,104.999 C84.433,104.999 92.899,97.665 94.924,84.628 L94.924,103.755 L114.458,103.755 L114.458,67.295 L119.043,67.295 C129.387,67.295 136.672,63.244 140.448,55.635 L140.448,103.755 L159.989,103.755 L159.989,64.25 L162.696,64.25 C167.408,64.25 169.96,65.832 169.96,74.193 L169.96,89.444 C169.96,97.208 170.467,99.17 171.373,101.968 L171.95,103.755 L192.279,103.755 L190.929,100.457 C189.593,97.201 189.495,94.036 189.495,89.578 L189.495,89.578 Z M46.616,65.445 C44.169,58.976 39.464,52.957 31.758,45.946 C21.766,36.91 19.937,32.354 19.937,26.651 C19.937,20.027 22.511,19.471 24.923,19.471 C27.334,19.471 29.908,20.027 29.908,26.651 L29.908,33.057 L46.616,33.057 L46.616,65.445 Z M159.715,20.582 L164.433,20.582 C167.134,20.582 169.552,21.166 169.552,28.17 L169.552,37.74 C169.552,44.322 167.183,45.601 163.097,45.601 L159.715,45.601 L159.715,20.582 Z M123.895,27.34 L123.895,41.896 C123.895,48.154 121.792,48.653 119.043,48.653 L114.458,48.653 L114.458,20.582 L119.043,20.582 C121.792,20.582 123.895,21.082 123.895,27.34 L123.895,27.34 Z"
          fill="currentColor"
        ></path>
        <polyline
          fill="currentColor"
          points="249.828 1.938 240.427 39.291 231.018 1.938 196.021 1.938 196.021 103.752 215.555 103.752 215.555 19.314 229.984 69.542 229.984 103.752 249.518 103.752 249.518 69.542 268.955 1.938 249.828 1.938"
        ></polyline>
        <polyline
          fill="currentColor"
          points="454.242 1.938 454.242 85.11 435.622 85.11 435.622 1.938 416.087 1.938 416.087 103.752 499.259 103.752 499.259 85.11 473.769 85.11 473.769 61.469 494.028 61.469 494.028 42.835 473.769 42.835 473.769 20.579 499.259 20.579 499.259 1.938 454.242 1.938"
        ></polyline>
        <path
          d="M272.409,1.938 L256.643,103.752 L275.045,103.752 L277.598,86.144 L291.563,86.144 L294.116,103.752 L313.861,103.752 L298.095,1.938 L272.409,1.938 Z M284.517,37.561 L288.87,68.199 L280.164,68.199 L284.517,37.561 L284.517,37.561 Z"
          fill="currentColor"
        ></path>
        <polyline
          fill="currentColor"
          points="366.578 1.938 366.578 85.11 347.325 85.11 347.325 1.938 327.79 1.938 327.79 103.752 411.603 103.752 411.603 85.11 386.113 85.11 386.113 61.469 406.364 61.469 406.364 42.835 386.113 42.835 386.113 20.579 411.603 20.579 411.603 1.938 366.578 1.938"
        ></polyline>
      </g>
    </svg>
  )
}

export default Logo
